<template>
  <div class="project" :class="{ thumbnail: image }" :style="imgPath">
    <a v-if="isExternal" :href="index" target="_blank">
      <slot />
    </a>
    <a v-else-if="isFilm" @click="playFilm">
      <slot />
    </a>
    <router-link v-else :to="routeObj">
      <slot />
    </router-link>
  </div>
</template>

<script>
import store from "@/store";
import { OPEN_VIDEO_MODAL } from "@/store/handlers";

export default {
  props: ["directory", "folder", "index", "image"],
  computed: {
    imgPath() {
      return this.image
        ? `--bg-img: url(${process.env.BASE_URL}img/${this.directory}/${this.folder}/${this.image})`
        : null;
    },
    isExternal() {
      return (
        typeof this.index === "string" &&
        (this.index.startsWith("http") ||
          this.index.startsWith("mailto:") ||
          this.index.startsWith("tel:"))
      );
    },
    isFilm() {
      return this.folder === "film";
    },
    routeObj() {
      var obj = this.isExternal
        ? null
        : {
            name: [this.directory, this.folder, this.index].join("."),
            params: { internal: true },
          };
      return obj;
    },
  },
  methods: {
    playFilm() {
      store.dispatch(OPEN_VIDEO_MODAL, this.index);
    },
  },
};
</script>

<style>
.directory .grid .project {
  position: relative;
  height: var(--grid-cell-height);
  float: left;
  overflow: hidden;
}

.directory .grid .project a {
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.directory .grid .thumbnail:before {
  content: "";
  position: absolute;
  display: inline-block;
  height: inherit;
  width: 100%;
  background-image: var(--bg-img);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  border-right: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
}

.directory .grid .thumbnail:hover:before {
  opacity: 0.6;
}

.directory .grid .project a div {
  display: table-cell;
  padding: 0 0.75em;
  text-align: center;
  word-break: break-word;
  vertical-align: middle;
}
</style>
